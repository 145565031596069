import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { VALID_IMAGE } from '@/utils/config'

export default function addProduct() {
  const status = ref(false)
  const imgSrc = ref('https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png')
  const arrayRatingConfig = ref([
    {
      name: '',
      languageCode: '',
      slug: '',
      description: '',
    },
  ])
  const countRatingConfig = ref(1)
  const idRatingConfig = ref(null)
  const numberDefault = ref(null)
  const loading = ref(false)
  // Fetch post
  if (router.currentRoute.params.id) {
    loading.value = true
    idRatingConfig.value = router.currentRoute.params.id
    store
      .dispatch('rating/fetchRating', { id: idRatingConfig.value })
      .then(response => {
        const res = response.body
        status.value = {
          label: store.state.rating.optionsActive.find(x => x.value === res.isActive).label,
          value: res.isActive,
        }
        countRatingConfig.value = res.translations.length
        arrayRatingConfig.value = res.translations.map(x => ({
          title: x.title,
          languageCode: store.state.app.lanCodeOptions.find(e => e.value === x.languageCode),
          isDefaultLanguage: x.isDefaultLanguage,
        })).reverse()
        numberDefault.value = arrayRatingConfig.value.findIndex(x => x.isDefaultLanguage)
        if (numberDefault.value === -1) {
          numberDefault.value = null
        }
        loading.value = false
      })
  }

  return {
    arrayRatingConfig,
    countRatingConfig,
    status,
    imgSrc,
    loading,
    numberDefault,
  }
}
